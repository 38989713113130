<template>
  <div>
    <PGalleria
      :value="images"
      :responsiveOptions="responsiveOptions"
      :numVisible="1"
      containerStyle="max-width: 80%"
      :fullScreen="true"
      :visible.sync="displayFull"
    >
      <template #item="slotProps">
        <img
          :src="slotProps.item"
          v-if="slotProps.item != mediaPlaceholder"
          :alt="'Photo'"
          style="width: 100%; display: block"
        />
      </template>
      <template #thumbnail="slotProps">
        <template v-if="slotProps.item == mediaPlaceholder">
          <div class="text-white text-uppercase mb-1">
            Entrez le mot de passe du fichier {{ photoUrl }}
          </div>
          <div class="d-flex justify-content-between mt-2">
            <input
              class="form-control"
              type="password"
              name="password"
              value="password"
            />
            <button class="btn btn-warning rounded-0 mx-2" @click.prevent="submit">
              Valider
            </button>
          </div>
        </template>
        <div v-else class="text-white text-uppercase">GALLERIE MEDIA</div>
      </template>
    </PGalleria>
  </div>
</template>

<script>
import { lazyDownloadFileWithAxiosPost } from "@/api/api.js";
import { ENCRYPTED_FILE_DOWNLOAD_API_URL } from "../config/api-config";
function toBase64(dataArr) {
  return Buffer.from(dataArr, "base64");
}
export default {
  data() {
    return {
      mediaUrl: "",
      displayFull: true,
      mediaPlaceholder: "https://picsum.photos/seed/picsum/700/500",
      images: [],
      responsiveOptions: [
        {
          breakpoint: "1024px",
          numVisible: 5,
        },
        {
          breakpoint: "960px",
          numVisible: 4,
        },
        {
          breakpoint: "768px",
          numVisible: 3,
        },
        {
          breakpoint: "560px",
          numVisible: 1,
        },
      ],
    };
  },
  created() {
    this.images = [this.mediaPlaceholder];
  },
  computed: {
    photoUrl() {
      return this.$route.query.photoUrl;
    },
  },
  methods: {
    async loadMedia() {
      lazyDownloadFileWithAxiosPost(
        ENCRYPTED_FILE_DOWNLOAD_API_URL,
        this.photoUrl.split("/").pop(),
        {
          fileUrl: this.photoUrl,
        },
        () => {},
        false
      ).then((data) => {
        console.log(data);
        this.images = [data];
      });
    },
    async submit() {
      this.images = ["https://picsum.photos/800/600"];
      await this.loadMedia();
    },
  },
};
</script>

<style>
.p-galleria-thumbnail-next.p-link,
.p-galleria-thumbnail-prev.p-link {
  display: none !important;
}
.p-galleria-content {
  padding: 20px 40px;
  border: 1px solid rgba(255, 255, 255, 0.4);
}
</style>
